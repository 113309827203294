
.mainContent{
  display: flex;
}

.content{
  background-color: #f5f6fa;
  width: calc(100vw - 250px);
  min-height: calc(100vh - 68px);
  float: right;
  padding-left: 50px;
  padding-top: 30px;
}

.active{
  background: #86fefa;
}

.break-me{
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.page{
  padding: 4px 12px;
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 5px;
  margin-top: 6px; 
  margin-bottom: 10px;
}