.modalBackground{
  width: 100vw;
  height: 100vh;
  background-color: #00000031;
  /* opacity: .3; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.modalContainer{
  width: 60%;
  height: 85%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title{
  display: inline-block;
  letter-spacing: 0px;
  color: #131523;
  opacity: 1;
  text-align: left;
}

h3{
  font-weight: bold;
  text-align: left;
}

.titleCloseBtn button{
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body{
  color: #000;
  flex: 50%;
  text-align: left;
}

label{
  margin-top: 25px;
  font-size: 18px;
}

textarea{
  border: 1px solid #828282;
  border-radius: 6px;
  width: 99%;
  height: 50%;
  max-height: 50%;
  margin-top: 10px;
}

.footer{
  display: flex;
  justify-content: flex-end;
  margin-right: 1%;
}

